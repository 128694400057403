import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  label?: string
  languageCode: string
  image?: { [key: string]: any }
  showModal?: boolean
  title?: string
}

export const Sticker = memo(function Sticker({
  cta,
  description,
  label,
  languageCode,
  image,
  showModal,
  title,
}: Props) {
  const [modalStickerStatus, setModalStickerStatus] = useState(false)
  const [modalForceShow, setModalForceShow] = useState(showModal)

  useEffect(() => {
    disableScroll.off()
  }, [])

  return (
    <Container>
      {label ? (
        <Label
          onClick={() => {
            if (!modalStickerStatus) {
              setModalStickerStatus(true)
              if (window.innerWidth > 1199) {
                disableScroll.on()
              } else {
                document.body.style.overflow = 'hidden'
              }
            } else {
              if (window.innerWidth > 1199) {
                disableScroll.off()
              } else {
                document.body.style.overflow = 'auto'
              }
              setModalStickerStatus(false)
            }
          }}
        >
          <LabelTitle>{label}</LabelTitle>
          <LabelSubtitle>
            {useVocabularyData('click-here', languageCode)}
          </LabelSubtitle>
        </Label>
      ) : null}

      <Modal
        className={modalStickerStatus || modalForceShow ? 'open' : ''}
        dial={5}
        onClick={() => {
          if (window.innerWidth > 1199) {
            disableScroll.off()
          } else {
            document.body.style.overflow = 'auto'
          }
          setModalStickerStatus(false)
          setModalForceShow(false)
        }}
      >
        <Wrapper row stretch onClick={(e) => e.stopPropagation()}>
          {image ? (
            <ImageWrap>
              <LazyLoad once>
                <Image {...image} />
              </LazyLoad>
            </ImageWrap>
          ) : null}

          <TextWrap className={!image ? 'full-width' : undefined}>
            <ModalClose
              onClick={() => {
                if (window.innerWidth > 1199) {
                  disableScroll.off()
                } else {
                  document.body.style.overflow = 'auto'
                }
                setModalStickerStatus(false)
                setModalForceShow(false)
              }}
            />
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta
              ? cta.map((item, index) => <Button key={index} {...item} />)
              : null}
          </TextWrap>
        </Wrapper>
      </Modal>
    </Container>
  )
})

const Container = styled.aside`
  padding: 0 1.5rem;
`

const Label = styled.div`
  border: 1.5px solid ${rgba(theme.colors.variants.neutralLight4, 0.2)};
  border-right: 0;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  padding: 2.75rem 1.5rem 2.75rem 2.25rem;
  position: absolute;
  top: 24vh;
  right: 0;
  z-index: 300;
  transition: 0.3s ease-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    border-color: transparent;
    padding-right: 2.25rem;
  }

  @media (max-width: 767px) {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    padding: 1.5rem;
    top: 60px;
    left: 0;
    text-align: center;
  }
`

const LabelTitle = styled.div`
  max-width: 6.25rem;
  text-transform: uppercase;

  @media (max-width: 767px) {
    max-width: none;
  }
`

const LabelSubtitle = styled.div`
  font-style: italic;
  opacity: 0.3;
  margin-top: 0.375rem;
`

const Modal = styled(FlexBox)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

const ModalClose = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 20px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    position: absolute;
    top: 1.125rem;
    left: 0.625rem;
    transition: 0.3s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: default;
  margin: auto;
`

const ImageWrap = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05);
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const TextWrap = styled.div`
  width: 50%;
  padding: 3.75rem 3.75rem 4.875rem;
  position: relative;
  &.full-width {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.375rem;
  margin-bottom: 1.25rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`
